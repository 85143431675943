import { profileMappingKeys } from './mappings';
export default rawData => {
    let obj = {};

    Object.keys(profileMappingKeys).forEach(key => {
        obj[profileMappingKeys[key]] = rawData[key];
    });

    return obj;
};
