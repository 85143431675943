import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import queryString from '@/helpers/dtos/queryString';
import { _fetchDefaultParser, getFirstItemFromResponseArray } from '@/helpers/store';
import dashboardWalletFromAPI from '@/helpers/dtos/dashboardWalletFromAPI';
import {
    getUFLimiteUsado,
    getBalancesPerUF,
    getAverageClientExposure,
    getClientsCount,
    getDelayGrowLastDays,
    getBalanceDue,
    getOverdueBalance,
    getTop10Exposure,
    getAvailableCash,
    getTop40ClientsExposure,
    getOverdueClients,
    getWallet,
} from '../service';

export default {
    async fetchUpcomingDues({ commit }, { idProduct, filters }) {
        console.log(idProduct, filters);
        commit('setUpcomingDuesRequestStatus', RequestStatusEnum.START);

        try {
            commit('setUpcomingDues', fakeUpcomingDues);
            commit('setUpcomingDuesRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUpcomingDuesRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchFundWallet({ commit }, { idProduct, filters }) {
        commit('setFundWalletRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getWallet(idProduct, queryString(filters, {}));
            const fundWallet = dashboardWalletFromAPI(data.data.content);
            commit('setFundWallet', fundWallet);
            commit('setFundWalletRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setFundWalletRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchBalancesPerUF({ commit }, { idProduct, filters }) {
        commit('setBalancesPerUFRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getBalancesPerUF(idProduct, queryString(filters, {}));
            commit('setBalancesPerUF', data.data.content);
            commit('setBalancesPerUFRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setBalancesPerUFRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchLimitsUsagePerUF({ commit }, { idProduct, filters }) {
        commit('setLimitsUsagePerUFRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getUFLimiteUsado(idProduct, queryString(filters, {}));
            commit('setLimitsUsagePerUFRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setLimitsUsagePerUF', data.data.content);
        } catch (error) {
            commit('setLimitsUsagePerUFRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchAverageClientExposure({ commit }, { idProduct, filters }) {
        commit('setAverageClientExposureRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getAverageClientExposure(idProduct, queryString(filters, {}));
            commit('setAverageClientExposureRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setAverageClientExposure', data.data.content);
        } catch (error) {
            commit('setAverageClientExposureRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchClientsCount({ commit }, { idProduct, filters }) {
        commit('setClientsCountRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getClientsCount(idProduct, queryString(filters, {}));
            commit('setClientsCount', getFirstItemFromResponseArray(data));
            commit('setClientsCountRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setClientsCountRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchDelayGrowLastDays({ commit }, { idProduct, filters }) {
        commit('setDelayGrowLastDaysRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getDelayGrowLastDays(idProduct, queryString(filters, {}));
            commit('setDelayGrowLastDaysRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setDelayGrowLastDays', data.data.content);
        } catch (error) {
            commit('setDelayGrowLastDaysRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchBalanceDue({ commit }, { idProduct, filters }) {
        commit('setBalanceDueRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getBalanceDue(idProduct, queryString(filters, {}));
            commit('setBalanceDueRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setBalanceDue', getFirstItemFromResponseArray(data));
        } catch (error) {
            commit('setBalanceDueRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOverdueBalance({ commit }, { idProduct, filters }) {
        commit('setOverdueBalanceRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getOverdueBalance(idProduct, queryString(filters, {}));
            commit('setOverdueBalance', getFirstItemFromResponseArray(data));
            commit('setOverdueBalanceRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setOverdueBalanceRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchTop10Exposure({ commit }, { idProduct, filters }) {
        commit('setTop10ExposureRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getTop10Exposure(idProduct, queryString(filters, {}));
            commit('setTop10ExposureRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setTop10Exposure', data.data.content);
        } catch (error) {
            commit('setTop10ExposureRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchAvailableCash({ commit }, { idProduct, filters }) {
        commit('setAvailableCashRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getAvailableCash(idProduct, queryString(filters, {}));
            commit('setAvailableCashRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setAvailableCash', getFirstItemFromResponseArray(data));
        } catch (error) {
            commit('setAvailableCashRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchTop40ClientsExposure({ commit }, { idProduct, filters }) {
        commit('setTop40ClientsExposureListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getTop40ClientsExposure(idProduct, params);
            _fetchDefaultParser(commit, data, null, 'Top40ClientsExposureList');
        } catch (error) {
            commit('setTop40ClientsExposureListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOverdueClients({ commit }, { idProduct, filters }) {
        commit('setOverdueClientsListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getOverdueClients(idProduct, params);
            _fetchDefaultParser(commit, data, null, 'OverdueClientsList');
        } catch (error) {
            commit('setOverdueClientsListRequestStatus', newErrorBuilder(error));
        }
    },

    resetState({ commit }) {
        commit('resetState');
    },

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },
};

const fakeUpcomingDues = [
    {
        name: 'Valor Notas a Vencer',
        data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
    },
    {
        name: 'Valor Notas Pagamentos',
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    },
];
