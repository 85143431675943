import state from './state';
import actionsTitulos from './actions.titulos';
import actionsFactoring from './actions.factoring';
import mutations from './mutations';
import getters from './getters';

export default {
    namespaced: true,
    state: { ...state },
    actions: {
        ...actionsTitulos,
        ...actionsFactoring,
    },
    mutations,
    getters,
};
