import defaultState from './state';
export default {
    resetState(state) {
        Object.assign(state, defaultState);
    },
    setToken(state, token) {
        state.token = token;
    },
    resetToken(state) {
        state.token = '';
    },
    setPermanecerConectado(state, pc) {
        state.permanecerConectado = pc;
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    setProfileRequestStatus(state, status) {
        state.profileRequestStatus = status;
    },
    setSignInRequestStatus(state, status) {
        state.signInRequestStatus = status;
    },
    setAskPasswordResetRequestStatus(state, status) {
        state.askPasswordResetRequestStatus = status;
    },
    setPasswordRecoveryRequestStatus(state, status) {
        state.passwordRecoveryRequestStatus = status;
    },
};
