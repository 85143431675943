import { mutationsBuilder } from '@/helpers/store';
import basicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';

export default {
    ...mutationsBuilder(basicKeys),
    ...mutationsBuilder(requestStatusKeys),
    setBaseData(state, { selectedProduct }) {
        state.selectedProduct = selectedProduct;
    },
};
