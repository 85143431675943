import { formatarData } from '@/helpers/data';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';

export default el => ({
    name: el.cliente,
    raiz: el.raizCpf,
    id: el.idSolicitacao,
    createdAt: formatarData(el.createdAt),
    product: el.produto,
    status: el.statusInt,
    manager: el.gerente,
    state: el.estado,
    guarantee: 'N/A',
    approvedAmount: formatarDinheiro(el.aprovado),
    inUseAmount: formatarDinheiro(el.utilizado),
    movementAmount: formatarDinheiro(el.movimento),
    availableAmount: formatarDinheiro(el.disponivel),
    decisionDate: formatarData(el.dataAprovacao),
    validityDate: formatarData(el.fim),
    updatedAt: formatarData(el.dataAtualizacao),
});