const Solicitacao = () => import('@/mfes/credito/views/Solicitacao');
const SolicitarValor = () => import('@/mfes/credito/views/SolicitarValor');
const SolicitarLote = () => import('@/mfes/credito/views/SolicitarLote');
const ConsultaCredito = () => import('@/mfes/credito/views/Consulta');
const DetalhesLimite = () => import('@/mfes/credito/views/Detalhes');
const DetalhesGrupo = () => import('@/mfes/credito/views/DetalhesGrupo');

export default [
    {
        path: 'limites/solicitar',
        name: 'Solicitacao',
        component: Solicitacao,
        meta: { title: 'Solicitação de limite de crédito' },
    },
    {
        path: 'limites/solicitar_lote',
        name: 'SolicitarLote',
        component: SolicitarLote,
        meta: { title: 'Solicitação em lote' },
    },
    
    {
        path: 'limites/consultar',
        name: 'Consulta creditos',
        component: ConsultaCredito,
        meta: {
            title: 'Consulta de limite de crédito',
            icon: 'credit-card',
        },
    },
    {
        path: 'limites/solicitar/:document/valor',
        name: 'SolicitarValor',
        component: SolicitarValor,
        meta: { title: 'Nova Solicitação' },
    },
    {
        path: 'limites/:id/cliente',
        name: 'Detalhe Consulta',
        component: DetalhesLimite,
        meta: { title: 'Limite' },
    },
    {
        path: 'limites/:id/grupo',
        name: 'Detalhe do grupo',
        component: DetalhesGrupo,
        meta: { title: 'Limite' },
    },
];
