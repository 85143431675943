export default [
    'titulosList',
    'titulosListTotalPages',
    'resumeTitulos',
    'followUpList',
    'followUpListTotalPages',
    'titulosModalList',
    'titulosModalListTotalPages',
    'outgoesList',
    'outgoesListTotalPages',
    'openOutgoesList',
    'openOutgoesListTotalPages',
    'deliveriesList',
    'deliveriesListTotalPages',
    'exportTitulosFromOutgoFile',

    'clientsModalList',
    'clientsModalListTotalPages',
    'NFsModalList',
    'NFsModalListTotalPages',
    'exportFromFactoryOutgoFile',
];
