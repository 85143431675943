import { initProduct } from '@/store/localStorage/localStorage';

import { buildStateRequestStatus } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';

export default {
  products: [],
  originators: [],
  selectedProduct: initProduct,
  loading: false,
  menuOnMobile: false,
  ...buildStateRequestStatus(requestStatusKeys),
};