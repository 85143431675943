import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import {
    getTitulos,
    getResume,
    newOutgo,
    listEligiblesTitulos,
    listIneligiblesTitulos,
    getOutgoes,
    cancelOutgo as cancelOutgoService,
    downloadIneligiblesFromOutgo,
    downloadEligiblesFromOutgo,
    sendOutgo as sendOutgoService,
    removeTitulosFromOutgo,
} from '../service';
import queryString from '@/helpers/dtos/queryString';
import tituloFromAPI from '@/helpers/dtos/tituloFromAPI';
import tituloModalFromAPI from '@/helpers/dtos/tituloModalFromAPI';
import outgoFromAPI from '@/helpers/dtos/outgoFromAPI';
import { _fetchDefaultParser, _dismissDefaultParserState } from '@/helpers/store';

export default {
    async fetchTitulos({ commit }, { idProduct, status, filters }) {
        commit('setTitulosListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getTitulos(idProduct, status, params);
            _fetchDefaultParser(commit, data, tituloFromAPI, 'TitulosList');
        } catch (error) {
            commit('setTitulosListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchFollowUp({ commit }, { idProduct, status, filters }) {
        commit('setFollowUpListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getTitulos(idProduct, status, params);
            _fetchDefaultParser(commit, data, tituloFromAPI, 'FollowUpList');
        } catch (error) {
            commit('setFollowUpListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOutgoes({ commit }, { idProduct, status, filters }) {
        commit('setOutgoesListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getOutgoes(idProduct, status, params);
            _fetchDefaultParser(commit, data, outgoFromAPI, 'OutgoesList');
        } catch (error) {
            commit('setOutgoesListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOpenOutgoes({ commit }, { idProduct, status, filters }) {
        commit('setOpenOutgoesListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {}) + '&limit=100&page=0';
            const { data } = await getOutgoes(idProduct, status, params);
            _fetchDefaultParser(commit, data, outgoFromAPI, 'OpenOutgoesList');
        } catch (error) {
            commit('setOpenOutgoesListRequestStatus', newErrorBuilder(error));
        }
    },

    dismissOpenOutgoes({ commit }) {
        _dismissDefaultParserState(commit, 'OpenOutgoesList');
    },

    async fetchResume({ commit }, { idProduct }) {
        commit('setResumeTitulosRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getResume(idProduct);
            commit('setResumeTitulos', data.data);
            commit('setResumeTitulosRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setResumeTitulosRequestStatus', newErrorBuilder(error));
        }
    },

    async askForNewOutgo({ commit }, { idProduct, date, ids }) {
        commit('setAskForNewOutgoRequestStatus', RequestStatusEnum.START);
        try {
            const payload = { data: date, idsLastros: ids };
            await newOutgo(idProduct, payload);
            commit('setAskForNewOutgoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setAskForNewOutgoRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchTitulosModal({ commit }, { idProduct, outgoId, type, page }) {
        commit('setTitulosModalListRequestStatus', RequestStatusEnum.START);
        try {
            const params = `page=${page}&limit=10`;
            const { data } =
                type === 'eligibles'
                    ? await listEligiblesTitulos(idProduct, outgoId, params)
                    : await listIneligiblesTitulos(idProduct, outgoId, params);
            _fetchDefaultParser(commit, data, tituloModalFromAPI, 'TitulosModalList');
        } catch (error) {
            commit('setTitulosModalListRequestStatus', newErrorBuilder(error));
        }
    },

    async cancelOutgo({ commit }, { idProduct, outgoId }) {
        commit('setCancelOutgoRequestStatus', RequestStatusEnum.START);
        try {
            await cancelOutgoService(idProduct, outgoId);
            commit('setCancelOutgoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setCancelOutgoRequestStatus', newErrorBuilder(error));
        }
    },

    async removeTitulosFromOutgo({ commit }, { idProduct, outgoId, removedIds }) {
        commit('setRemoveOutgoRequestStatus', RequestStatusEnum.START);
        try {
            await removeTitulosFromOutgo(idProduct, outgoId, removedIds);
            commit('setRemoveOutgoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setRemoveOutgoRequestStatus', newErrorBuilder(error));
        }
    },

    async sendOutgo({ commit }, { idProduct, outgoId }) {
        commit('setSendOutgoRequestStatus', RequestStatusEnum.START);
        try {
            await sendOutgoService(idProduct, outgoId);
            commit('setSendOutgoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setSendOutgoRequestStatus', newErrorBuilder(error));
        }
    },

    async exportTitulosFromOutgo({ commit }, { idProduct, outgoId, type }) {
        commit('setExportTitulosFromOutgoFileRequestStatus', RequestStatusEnum.START);
        try {
            const data =
                type === 'eligibles'
                    ? await downloadEligiblesFromOutgo(idProduct, outgoId)
                    : await downloadIneligiblesFromOutgo(idProduct, outgoId);
            commit('setExportTitulosFromOutgoFile', { file: data.data, meta: { type, outgoId } });
            commit('setExportTitulosFromOutgoFileRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setExportTitulosFromOutgoFileRequestStatus', newErrorBuilder(error));
        }
    },

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },
};
