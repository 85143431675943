export const CreditStatusColors = {
    1: { label: 'Pré-Aprovado', color: 'status-chip-success' },
    2: { label: 'Suspenso', color: 'status-chip-danger' },
    3: { label: 'Indisponível', color: 'status-chip-danger' },
    4: { label: 'Solicitação', color: 'status-chip-warning' },
    5: { label: 'Vencido', color: 'status-chip-warning' },
};

export const RegistersStatus = {
    0: { label: 'Pendente', color: 'status-chip-pending' },
    1: { label: 'Completo', color: 'status-chip-complete' },
};

const defaultStatusColors = {
    1: 'status-chip-complete',
    2: 'status-chip-success',
    3: 'status-chip-pending',
    4: 'status-chip-danger',
};
const defaultStatusColorsInverted = {
    1: 'status-chip-pending',
    2: 'status-chip-success',
    3: 'status-chip-complete',
    4: 'status-chip-danger',
};

export const getStatusColorForChip = id => defaultStatusColors[id];
export const getStatusColorForChipInverted = id => defaultStatusColorsInverted[id];

export const RequestStatusEnum = {
    START: 'START',
    IDLE: 'IDLE',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const newErrorBuilder = error => {
    let err = {
        type: RequestStatusEnum.ERROR,
    };
    if (error.response) {
        err.httpStatus = error.response.status;
        if (error.response.data) {
            const data = error.response.data;
            if (data && data.data) {
                err.message = data.data;
            }
            if (data && data.message) {
                err.message = data.message;
            }
            if (data.errors && data.errors.length > 0) {
                err.message = data.errors
                    .map(item =>
                        item && item.defaultMessage ? item.defaultMessage : 'erro inesperado'
                    )
                    .join('. ');
            }
        }
    }
    return err;
};
