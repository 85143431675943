import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import storage from '@/helpers/localStorageObject';

import _middlewares from './middlewares';

const { checkAuth } = _middlewares(storage);

Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(checkAuth);

export default router;
