const Titulos = () => import('./views/Titulos');
const Remessas = () => import('./views/Remessas');

export default [
    {
        path: 'titulos/:step',
        name: 'Gestão de Títulos - Lista',
        component: Titulos,
        meta: { title: 'Gestão de Títulos', type: 'origination', filters: { tipo: '1,2' } },
    },
    { path: 'titulos', redirect: 'titulos/lista' },
    {
        path: 'factoring/:step',
        name: 'Gestão de Remessas - Lista',
        component: Remessas,
        meta: { title: 'Gestão de Remessas', type: 'factoring', filters: { tipo: '3' } },
    },
];
