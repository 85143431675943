import defaultState from './state';
import { mutationsBuilder } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';
import basicKeys from './_basicKeys';

export default {
    resetState(state) {
        const selectedProduct = { ...state.selectedProduct };
        Object.assign(state, defaultState);
        state.selectedProduct = selectedProduct;
    },
    setBaseData(state, { selectedProduct }) {
        state.selectedProduct = selectedProduct;
    },
    ...mutationsBuilder(requestStatusKeys),
    ...mutationsBuilder(basicKeys),
};
