import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/mfes/auth/store';
import dashboards from '@/mfes/dashboards/store';
import importacoes from '@/mfes/importacoes/store';
import cadastros from '@/mfes/cadastros/store';
import originacao from '@/mfes/originacao/store';
import credito from '@/mfes/credito/store';
import gestao from '@/mfes/gestao/store';

import base from './modules/base';
import admin from './modules/admin';

import localStoragePlugin from "./localStorage/localStorage";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        admin,
        dashboards,
        base,
        importacoes,
        originacao,
        cadastros,
        credito,
        gestao,
    },
    plugins: [localStoragePlugin]
});
