import { mutationsBuilder} from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';

export default {
    setProducts(state, products) {
        state.products = products;
    },
    setOriginators(state, originators) {
        state.originators = originators;
    },
    setSelectedProduct(state, id) {
        state.selectedProduct = id;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    toggleMenuOnMobile(state) {
        state.menuOnMobile = !state.menuOnMobile;
    },
    ...mutationsBuilder(requestStatusKeys),
};
