export const register = {
    id: 'id',
    tipo: 'type',
    tipoInt: 'intType',
    status: 'status',
    nome: 'name',
    fantasia: 'fantasyName',
    documento: 'document',
    site: 'site',
    email: 'email',
    telefone: 'phone',
    celular: 'mobilePhone',
    cidade: 'city',
    estado: 'state',
    cep: 'zipCode',
    codigo: 'code',
    abertura: 'startAt',
    raiz: 'root',
    natureza: 'nature',
    password: 'password',
};

export const registerConfig = {
    segmentacao: 'segment',
    id: 'id',
    idProduto: 'idProduto',
    idCadastro: 'idCadastro',
    relacionamento: 'relationship',
};

export const requestLimit = {
    cep: 'zipCode',
    natureza: 'nature',
    segmentacao: 'segment',
    id: 'id',
    idProduto: 'idProduto',
    valor: 'value',
    documento: 'document',
    nome: 'name',
    abertura: 'startAt',
    estado: 'state',
    cidade: 'city',
};

export const limitsMappingKeys = {
    chave: 'key',
    idProduto: 'idProduto',
};

export const managerMappingKeys = {
    id: 'id',
    nome: 'name',
};

export const profileMappingKeys = {
    id: 'id',
    nome: 'nome',
    fantasia: 'fantasia',
    email: 'email',
    tipoInt: 'tipoInt',
};

export const cadastroRegisterMappingKeys = {
    id: 'id',
    nome: 'name',
    documento: 'documento',
    relacionamento: 'relationship',
    status: 'status',
    produto: 'product',
    idTipoArquivo: 'idTipoArquivo',
    idProduto: 'idProduto',
    idStatus: 'idStatus',
};

export const tituloMappingKeys = {
    idLastro: 'coverageId',
    tipo: 'type',
    fornecedor: 'provider',
    valorNominal: 'nominalValue',
    totalNotas: 'totalNotes',
    vencimento: 'dueDate',
};

export const noteMappingKeys = {
    tipoString: 'stringType',
    tipoInt: 'intType',
    numero: 'number',
    emissao: 'emissionDate',
    vencimento: 'dueDate',
    fornecedor: 'provider',
    cliente: 'client',
    valor: 'value',
};

export const tituloModalMappingKeys = {
    id_desembolso: 'outgoId',
    id_lastro: 'coverageId',
    id_cliente: 'clientId',
    nomeCliente: 'clientName',
    elegibilidade: 'eligibility',
    tipoLastro: 'coverageType',
    numeroLastro: 'coverageNumber',
    vencimentoLastro: 'coverageDueDate',
};

export const outgoMappingKeys = {
    id_desembolso: 'id',
    data: 'date',
    qtdTitulos: 'countTitulos',
    qtdTitulosElegiveis: 'countEligibles',
    statusElegibilidade: 'statusEligibility',
    qtdTitulosNaoElegiveis: 'countIneligibles',
    valorNominal: 'value',
    status: 'status',
    cdca: 'cdca',
    cprf: 'cprf',
    factory: 'factory',
};

export const originationMappingKeys = {
    id_cliente: 'id',
    nome_cliente: 'name',
    status_cadastro: 'registry',
    status_credito: 'credit',
    limite_atual: 'available',
    limite_simulado: 'simulated',
};

export const deliveryMappingKeys = {
    id_nota: 'id',
    feita_em: 'createdAt',
    valor: 'value',
    numero: 'number',
    emissao: 'issuedAt',
    vencimento: 'dueDate',
    cliente: 'name',
    id_lastro: 'coverageId',
};

export const clientOutgoMappingKeys = {
    id_cliente: 'id',
    cliente: 'name',
    documento: 'document',
    qtd_notas: 'notasTotal',
    valor_nominal: 'value',
    desembolso: 'date',
    id_produto: 'idProduct',
};

export const NFClientDelivery = {
    id_nota: 'id',
    id_produto: 'idProduct',
    id_cliente: 'idClient',
    id_sessao: 'idSession',
    id_lastro: 'idLastro',
    numero: 'number',
    emissao: 'issuedAt',
    vencimento: 'dueDate',
    fornecedor: 'provider',
    valor: 'value',
};

export const manufacturerMappingKeys = {
    id: 'id',
    cliente: 'customer',
    documento: 'document',
    status: 'status',
    relacionamento: 'relationshipDate',
    estado: 'state',
    gerente: 'manager',
    reponsavel: 'responsible',
    responsavelEmail: 'responsibleEmail',
    responsavelTelefone: 'responsiblePhone',
    responsavelCelular: 'responsibleCellphone',
    adesaoPrograma : 'adherence',
    liberacaoOriginador : 'originatorLiberation',
    segmentacao : 'rating',
    signatario : 'signatory',
    revendas : 'resale',
    assinaturaMandato: 'signature',
    tipoEndereco: 'addressType',
    endereco: 'address',
    cep: 'zipCode',
    numero: 'number',
    complemento: 'complement',
    bairro: 'neighborhood',
    cidade: 'city',
    cadastroCompleto: 'fullRegistration',
};

export const manufacturerCompanyMappingKeys = {
    inscricaoEstadual: 'stateSubscription',
    inscricaoRural: 'ruralSubscription',
    site: 'site',
    email: 'email',
    telefone: 'phone',
    celular: 'cellphone',    
};