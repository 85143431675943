import { 
    manufacturerMappingKeys, manufacturerCompanyMappingKeys
} from './mappings';

export default rawData => {
    const keyGeneral = rawData.documento.length > 11 ? manufacturerCompanyMappingKeys : {};
    const keys = { 
        ...manufacturerMappingKeys,
        ...keyGeneral
    };

    let obj = {};
    
    Object.keys(keys).forEach(key => {
        obj[keys[key]] = rawData[key];
    });
    obj.addressType = obj.addressType === 1 ? 'Comercial' : 'Residencial';
    obj.fullRegistration = obj.fullRegistration === 1 ? 'Sim' : 'Não';
    obj.signatory = obj.signatory ===1 ? 'Sim' : 'Não';
    return obj;
};