import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import {
    getDeliveries,
    listEligiblesClients,
    listIneligiblesClients,
    listEligiblesNFsFromClient,
    listIneligiblesNFsFromClient,
    downloadEligiblesClientsFromOutgo,
    downloadIneligiblesClientsFromOutgo,
    downloadEligiblesClientNFsFromOutgo,
    downloadIneligiblesClientNFsFromOutgo,
    deleteClientsFromOutgo,
    deleteNFsFromOutgo,
} from '../service';
import queryString from '@/helpers/dtos/queryString';
import deliveryFromAPI from '@/helpers/dtos/deliveryFromAPI';
import { _fetchDefaultParser, _dismissDefaultParserState } from '@/helpers/store';
import clientOutgoFromAPI from '@/helpers/dtos/clientOutgoFromAPI';
import NFClientDeliveryFromAPI from '@/helpers/dtos/NFClientDeliveryFromAPI';

export default {
    async fetchDeliveries({ commit }, { idProduct, filters }) {
        commit('setDeliveriesListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getDeliveries(idProduct, params);
            const _data = {
                data: {
                    content: data.content,
                    totalPages: data.totalPages,
                },
            };
            _fetchDefaultParser(commit, _data, deliveryFromAPI, 'DeliveriesList');
        } catch (error) {
            commit('setDeliveriesListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchClientsModal({ commit }, { idProduct, outgoId, type, page }) {
        commit('setClientsModalListRequestStatus', RequestStatusEnum.START);
        try {
            const params = `page=${page}&limit=10`;
            const { data } =
                type === 'eligibles'
                    ? await listEligiblesClients(idProduct, outgoId, params)
                    : await listIneligiblesClients(idProduct, outgoId, params);
            const _data = {
                data: {
                    content: data.content,
                    totalPages: data.totalPages,
                },
            };
            _fetchDefaultParser(commit, _data, clientOutgoFromAPI, 'ClientsModalList');
        } catch (error) {
            commit('setClientsModalListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchNFsFromClientModal({ commit }, { idProduct, outgoId, clientId, type, page }) {
        commit('setNFsModalListRequestStatus', RequestStatusEnum.START);
        try {
            const params = `page=${page}&limit=10`;
            const { data } =
                type === 'eligibles'
                    ? await listEligiblesNFsFromClient(idProduct, outgoId, clientId, params)
                    : await listIneligiblesNFsFromClient(idProduct, outgoId, clientId, params);
            const _data = {
                data: {
                    content: data.content,
                    totalPages: data.totalPages,
                },
            };
            _fetchDefaultParser(commit, _data, NFClientDeliveryFromAPI, 'NFsModalList');
        } catch (error) {
            commit('setNFsModalListRequestStatus', newErrorBuilder(error));
        }
    },

    dismissNFsModalList({ commit }) {
        _dismissDefaultParserState(commit, 'NFsModalList');
    },

    async exportClientsFromOutgo({ commit }, { idProduct, outgoId, type }) {
        commit('setExportFromFactoryRequestStatus', RequestStatusEnum.START);
        try {
            const data =
                type === 'eligibles'
                    ? await downloadEligiblesClientsFromOutgo(idProduct, outgoId)
                    : await downloadIneligiblesClientsFromOutgo(idProduct, outgoId);
            commit('setExportFromFactoryOutgoFile', { file: data.data, meta: { type, outgoId } });
            commit('setExportFromFactoryRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setExportFromFactoryRequestStatus', newErrorBuilder(error));
        }
    },

    async exportClientNFsFromOutgo({ commit }, { idProduct, outgoId, clientId, type }) {
        commit('setExportFromFactoryRequestStatus', RequestStatusEnum.START);
        try {
              const data =
                type === 'eligibles'
                    ? await downloadEligiblesClientNFsFromOutgo(idProduct, outgoId, clientId)
                    : await downloadIneligiblesClientNFsFromOutgo(idProduct, outgoId, clientId);
            commit('setExportFromFactoryOutgoFile', {
                file: data.data,
                meta: { type, outgoId, clientId },
            });
            commit('setExportFromFactoryRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setExportFromFactoryRequestStatus', newErrorBuilder(error));
        }
    },

    async removeNFsByIdAndClients({ commit }, { idProduct, outgoId, clientIds, nfIds }) {
        commit('setRemoveNFsByIdAndClientRequestStatus', RequestStatusEnum.START);
        let promises = [];
        if (clientIds.length > 0) {
            promises.push(deleteClientsFromOutgo(idProduct, outgoId, clientIds));
        }
        if (nfIds.length > 0) {
            promises.push(deleteNFsFromOutgo(idProduct, outgoId, nfIds));
        }
        Promise.all(promises)
            .then(() => {
                commit('setRemoveNFsByIdAndClientRequestStatus', RequestStatusEnum.SUCCESS);
            })
            .catch(error => {
                commit('setRemoveNFsByIdAndClientRequestStatus', newErrorBuilder(error));
            });
    },
};
