import { buildStateRequestStatus, buildBasicState } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';
import basicKeys from './_basicKeys';

export default {
    ...buildBasicState(basicKeys),
    ...buildStateRequestStatus(requestStatusKeys),
    selectedProduct: null,
};


