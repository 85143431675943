import { gettersBuilder } from '@/helpers/store';
import stateBasicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';

export default {
    ...gettersBuilder(stateBasicKeys),
    ...gettersBuilder(requestStatusKeys),
    top40ClientsExposureListResult: state => ({
        items: state.top40ClientsExposureList,
        totalPages: state.top40ClientsExposureListTotalPages,
    }),
    overdueClientsListResult: state => ({
        items: state.overdueClientsList,
        totalPages: state.overdueClientsListTotalPages,
    }),
    selectedProduct: state => state.selectedProduct,
};
