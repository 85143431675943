import { uploadNotas} from '../service';
import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';

export default {
    async uploadCargaARRequest({ commit }, { idProduct, formData }) {
        commit('setUploadCargaARRequestStatus', RequestStatusEnum.START);
        try {
            await uploadNotas(idProduct, formData);
            commit('setUploadCargaARRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUploadCargaARRequestStatus', newErrorBuilder(error));
        }
    },

    async dismissUploadCargaARRequestStatus({ commit }) {
        commit('setUploadCargaARRequestStatus', RequestStatusEnum.IDLE);
    },

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },

};
