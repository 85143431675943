import { mutationsBuilder } from '@/helpers/store';
import basicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';

export default {
    setListClientsByGroup(state, { result, totalPages }) {
        state.listClientsByGroup = result;
        state.listClientsByGroupTotalPages = totalPages;
    },
    setLimits(state, { result, totalPages }) {
        state.limits = result;
        state.limitsPages = totalPages;
    },
    setLimitsPerBatch(state, { result, totalPages }) {
        state.limitsPerBatch = result;
        state.limitsPerBatchPages = totalPages;
    },
    setBaseData(state, { selectedProduct, originators }) {
        state.selectedProduct = selectedProduct;
        state.originators = originators;
    },
    ...mutationsBuilder(basicKeys),
    ...mutationsBuilder(requestStatusKeys),
};
