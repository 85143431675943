import { listOriginators } from '@/services/base';
import { RequestStatusEnum } from '@/helpers/consts';
import { newOriginatorFromAPI } from '@/helpers/dto_builders';

export default {
    async getOriginators({ commit }) {
        commit('setOriginatorsRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await listOriginators();
            const originators = data.data.map(originator => newOriginatorFromAPI(originator));
            commit('setOriginatorsRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setOriginators', originators);
        } catch (error) {
            commit('setOriginatorsRequestStatus', RequestStatusEnum.ERROR);
        }
    },

    updateCurrentProduct({ commit }, product) {
        commit('setSelectedProduct', product);
    },
    startLoading: ({ commit }) => {
        commit('setLoading', true);
    },
    stopLoading: ({ commit }) => {
        commit('setLoading', false);
    },

    toggleMenuOnMobile: ({ commit }) => {
        commit('toggleMenuOnMobile', false);
    },
};
