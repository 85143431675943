import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/router';
import store from './store';
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import VueApexCharts from 'vue-apexcharts';
import VueI18n from 'vue-i18n';

import messages from './i18n/pt-br';
// As a plugin
import VueMask from 'v-mask';
Vue.use(VueMask);

Vue.use(VuetifyDialog, {
    context: {
        vuetify,
    },
});
// https://github.com/apexcharts/vue-apexcharts
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'pt-br', // set locale
    messages, // set locale messages
});

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
