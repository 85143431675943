import axios from '@/axios';

const listRegisters = (idProduct, filters) => axios.get(`/v2/${idProduct}/documentos/solicitacoes?${filters}`);

const getManufacturers = (idProduct, filters) => axios.get(`/v2/cadastro/${idProduct}/produtores/listagem?${filters}`);

const getManufacturer = (idProduct, id) => axios.get(`/v2/cadastro/${idProduct}/produtor/${id}`);

const updateRegistryGeneral = (idProduct, id, payload) => axios.patch(`/v2/cadastro/${idProduct}/informacoes/gerais/${id}`, payload);

const updateRegistryConfig = (idProduct, id, payload) => axios.patch(`/v2/cadastro/${idProduct}/operacoes/${id}`, payload);

const exportManufacturers = (idProduct, filters) => axios.get(`/v2/cadastro/${idProduct}/produtores/export?${filters}`, {
    responseType: 'blob',
});

const fileTypesList = idProduct => axios.get(`/v2/${idProduct}/tipo_arquivo/`);

const uploadPendingDocuments = (idProduct, formData, idSolicitacao) => {
    return axios.post(
        `/v2/${idProduct}/solicitacoes/upload?idSolicitacao=${idSolicitacao}`,
        formData
    );
};

const listStates = () => axios.get(`/v2/enums/estados`);

const users = id => axios.get(`/v2/cadastro/tipo/${id}`);

const listSegments = (id) => axios.get(`/v2/cadastro/produtos/${id}/segmentos`);

export { 
    listRegisters, 
    uploadPendingDocuments, 
    fileTypesList, 
    getManufacturers, 
    listStates, 
    users, 
    exportManufacturers,
    updateRegistryGeneral,
    listSegments,
    updateRegistryConfig,
    getManufacturer
};
