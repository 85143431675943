export default [
    'managersList',
    'statusList',
    'limit',
    'findByDocumentResult',
    'segments',
    'states',
    'limitsPerRootResult',
    'downloadLimitsResult',

    'limitsFromClientList',
    'limitsFromClientListTotalPages',
];