export default [
    'titulosListRequestStatus',
    'resumeTitulosRequestStatus',
    'followUpListRequestStatus',
    'askForNewOutgoRequestStatus',
    'outgoesListRequestStatus',
    'titulosModalListRequestStatus',
    'cancelOutgoRequestStatus',
    'sendOutgoRequestStatus',
    'openOutgoesListRequestStatus',
    'exportTitulosFromOutgoFileRequestStatus',
    'removeOutgoRequestStatus',
    'deliveriesListRequestStatus',
    'clientsModalListRequestStatus',
    'NFsModalListRequestStatus',
    'exportFromFactoryRequestStatus',
    'removeNFsByIdAndClientRequestStatus',
];
