import { register as mappingKeys, registerConfig as registerConfigMappingKeys } from './mappings';
import { dateStringToISO } from "@/helpers/data";

export default rawData => {
    let obj = {};
    Object.keys(mappingKeys).forEach(key => {
        if (rawData[mappingKeys[key]])
            obj[key] = rawData[mappingKeys[key]];
    });

    if (obj.abertura) {
        obj.abertura = dateStringToISO(obj.abertura);
    }

    if (rawData.config) {
        obj.config = buildConfig(rawData);
    }

    return obj;
};

const buildConfig = _rawData => {
    let newObj = {};
    Object.keys(registerConfigMappingKeys).forEach(key => {
        if (_rawData.config[registerConfigMappingKeys[key]])
            newObj[key] = _rawData.config[registerConfigMappingKeys[key]];
    });
    return newObj;
};

