export default [
    'uploadPendingDocumentsRequestStatus',
    'pendingRegistersRequestStatus',
    'exportPendingRegistersStatus',
    'pendingDocumentsListRegistersRequestStatus',
    'fileTypesListRequestStatus',
    'manufacturersListRequestStatus',
    'manufacturerDetailRequestStatus',
    'updateManufacturerDetailRequestStatus',
    'exportManufacturersRequestStatus',
    'updateManufacturerDetailGeneralRequestStatus',
    'updateManufacturerConfigRequestStatus',
    'manufacturerDetailRequestStatus',
];
