import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import queryString from '@/helpers/dtos/queryString';
import { getManufacturers, exportManufacturers, updateRegistryGeneral, updateRegistryConfig, getManufacturer } from '../service';
import { _fetchDefaultParser } from '@/helpers/store';
import manufacturerFromAPI from '@/helpers/dtos/manufacturerFromAPI';

export default {
    async fetchManufacturer({ commit }, { idProduct, id }) {
        commit('setManufacturerDetailRequestStatus', RequestStatusEnum.START);
        try {            
            const { data } = await getManufacturer(idProduct, id);

            const _data = manufacturerFromAPI(data);

            commit('setManufacturerDetail', _data);            

            commit('setManufacturerDetailRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setManufacturerDetailRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchManufacturers({ commit }, { idProduct, filters }) {
        commit('setManufacturersListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            
            const { data } = await getManufacturers(idProduct, params);
            
            const _data = {
                data: {
                    content: data.content,
                    totalPages: data.totalPages,
                },
            };
            _fetchDefaultParser(commit, _data, manufacturerFromAPI, 'ManufacturersList');
            

            commit('setManufacturersListRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setManufacturersListRequestStatus', newErrorBuilder(error));
        }
    },

    async exportManufacturers({ commit }, { idProduct, filters }) {
        commit('setExportManufacturersRequestStatus', RequestStatusEnum.START);
        try {

            const params = queryString(filters, {});

            const data = await exportManufacturers(idProduct, params);

            commit('setExportManufacturers', { file: data.data });
            commit('setExportManufacturersRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setExportManufacturersRequestStatus', newErrorBuilder(error));
        }
    },

    async updateManufacturerGeneral({ commit }, { idProduct, id, payload }) {
        commit('setUpdateManufacturerDetailGeneralRequestStatus', RequestStatusEnum.START);
        try {

            await updateRegistryGeneral(idProduct, id, payload);
                        
            commit('setUpdateManufacturerDetailGeneralRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUpdateManufacturerDetailGeneralRequestStatus', newErrorBuilder(error));
        }
    },

    async updateManufacturerConfig({ commit }, { idProduct, id, payload }) {
        commit('setUpdateManufacturerConfigRequestStatus', RequestStatusEnum.START);
        try {

            await updateRegistryConfig(idProduct, id, payload);
                        
            commit('setUpdateManufacturerConfigRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUpdateManufacturerConfigRequestStatus', newErrorBuilder(error));
        }
    },

};
