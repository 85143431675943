export default [
    'upcomingDues',
    'limitsUsagePerUF',
    'balancesPerUF',
    'fundWallet',
    'averageClientExposure',
    'clientsCount',
    'delayGrowLastDays',
    'balanceDue',
    'top10Exposure',
    'availableCash',
    'overdueBalance',
    'top40ClientsExposureList',
    'top40ClientsExposureListTotalPages',
    'overdueClientsList',
    'overdueClientsListTotalPages',
];
