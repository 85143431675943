const AdminLayout = () => import('@/applications/admin/Layout');

const BaseLayoutGestao = () => import('@/mfes/gestao/BaseLayout');
const BaseLayoutImportacoes = () => import('@/mfes/importacoes/BaseLayout');
const BaseLayoutCadastros = () => import('@/mfes/cadastros/BaseLayout');
const BaseLayoutCredito = () => import('@/mfes/credito/BaseLayout');
const BaseLayoutDashboards = () => import('@/mfes/dashboards/BaseLayout');
const BaseLayoutOriginacao = () => import('@/mfes/originacao/BaseLayout');
const BaseLayoutAuth = () => import('@/mfes/auth/BaseLayout');

import dashboardsRoutes from '@/mfes/dashboards/routes';
import creditoRoutes from '@/mfes/credito/routes';
import cadastrosRoutes from '@/mfes/cadastros/routes';
import importacoesRoutes from '@/mfes/importacoes/routes';
import gestaoRoutes from '@/mfes/gestao/routes';
import originacaoRoutes from '@/mfes/originacao/routes';
import authRoutes from '@/mfes/auth/routes';

const routes = [
    {
        path: '/',
        name: 'baseAuth',
        redirect: { name: 'login' },
        component: BaseLayoutAuth,
        children: authRoutes
    },
    {
        path: '/admin',
        name: 'AdminLayout',
        component: AdminLayout,
        children: [
            {
                path: 'dashboard',
                component: BaseLayoutDashboards,
                children: dashboardsRoutes,
            },
            {
                path: 'credito',
                name: 'BaseLayoutCredito',
                component: BaseLayoutCredito,
                children: creditoRoutes,
            },
            {
                path: 'cadastros',
                name: 'BaseLayoutCadastros',
                component: BaseLayoutCadastros,
                children: cadastrosRoutes,
            },
            {
                path: 'importacoes',
                name: 'BaseLayoutImportacoes',
                component: BaseLayoutImportacoes,
                children: importacoesRoutes,
            },
            {
                path: 'gestao',
                name: 'BaseLayoutGestao',
                component: BaseLayoutGestao,
                children: gestaoRoutes,
            },
            {
                path: 'originacao',
                name: 'BaseLayoutOriginacao',
                component: BaseLayoutOriginacao,
                children: originacaoRoutes,
            },
        ],
    },
    { path: '*', redirect: '/produto' },
];

export default routes;
