// import { formatWithoutSymbol } from '@/helpers/formatarDinheiro';
import { formatarData } from '@/helpers/data';
import { calculateWithDecimals } from '@/helpers/percentage';

export default el => {
    const _cadastro = el.cadastro;
    if (!el.cadastros) {
        el.cadastros = [];
    }

    const grupo = _findGrupo(el.cadastros, _cadastro.idGrupo);

    const groupData = grupo ? _buildGrupoData(grupo) : {};
    return {
        name: _cadastro.nome,
        img: el.produto.foto,
        document: _cadastro.documento,
        decisionDate: el.dataAprovacao,
        validityDate: el.fim,
        updatedAt: el.updatedAt,
        availableAmount: el.disponivel,
        inUseAmount: el.utilizado,
        movementAmount: el.movimentoElegibilidade1,
        raiz: _cadastro.raiz,
        group: _cadastro.idGrupo,
        location: _cadastro.endereco,
        city: _cadastro.cidade,
        state: _cadastro.estado,
        comment: el.comentariosOriginador,
        value: el.valor,
        percentage: calculateWithDecimals(el.disponivel, el.valor, 0),
        groupData,
        groupLimit: el.limitegrupo,
        registers: el.cadastros.map(_register => _buildGrupoRegister(_register)),
    };
};

export const _findGrupo = (cadastros, idGrupo) => {
    const _query = cadastros.find(cadastro => cadastro.idGrupo === idGrupo);
    if (_query && _query.grupo) {
        return _query.grupo;
    }
    return null;
};

export const _buildGrupoRegister = _register => {
    return {
        id: _register.id,
        name: _register.nome,
        status: _register.status,
        raiz: _register.raiz,
        document: _register.documento,
        location: _register.endereco,
        city: _register.cidade,
        state: _register.estado,
        updatedAt: formatarData(_register.updatedAt),
        createdAt: formatarData(_register.createdAt),
    };
};

export const _buildGrupoData = grupo => ({
    name: grupo.nome,
    fantasyName: grupo.fantasia,
    zipCode: grupo.cep,
    neighborhood: grupo.bairro,
    document: grupo.documento,
    location: grupo.endereco,
    city: grupo.cidade,
    state: grupo.estado,
});
