import axios from '@/axios';

export const getTitulos = (idProduct, status, filters) =>
    axios.get(`/v2/${idProduct}/titulos/${status}?${filters}`);

export const getDeliveries = (idProduct, filters) =>
    axios.get(`/v2/${idProduct}/titulos/remessas?${filters}`);

export const getResume = idProduct => axios.get(`/v2/${idProduct}/titulos/grafico`);

export const cancelOutgo = (idProduct, outgoId) =>
    axios.delete(`/v2/${idProduct}/titulos/desembolsos/cancelar/${outgoId}`);

export const getOutgoes = (idProduct, status, filters) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${status}?${filters}`);

export const newOutgo = (idProduct, payload) =>
    axios.post(`/v2/${idProduct}/titulos/desembolsos/novo`, payload);

export const removeTitulosFromOutgo = (idProduct, outgoId, idsLastros) =>
    axios.delete(`/v2/${idProduct}/titulos/desembolsos/remover/titulos/${outgoId}`, {
        data: {
            idsLastros,
        },
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

export const sendOutgo = (idProduct, outgoId) =>
    axios.patch(`/v2/${idProduct}/titulos/desembolsos/enviar/${outgoId}`, {
        data: {},
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

export const listEligiblesTitulos = (idProduct, outgoId, params) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/titulos/elegiveis?${params}`);

export const downloadEligiblesFromOutgo = (idProduct, outgoId) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/elegiveis`, {
        responseType: 'blob',
    });

export const listIneligiblesTitulos = (idProduct, outgoId, params) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/titulos/naoelegiveis?${params}`);

export const downloadEligiblesClientsFromOutgo = (idProduct, outgoId) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/clientes/elegiveis`, {
        responseType: 'blob',
    });

export const downloadIneligiblesClientsFromOutgo = (idProduct, outgoId) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/clientes/elegiveis`, {
        responseType: 'blob',
    });

export const downloadIneligiblesFromOutgo = (idProduct, outgoId) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/naoelegiveis`, {
        responseType: 'blob',
    });

export const listEligiblesClients = (idProduct, outgoId, params) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/clientes/elegiveis?${params}`);

export const listIneligiblesClients = (idProduct, outgoId, params) =>
    axios.get(`/v2/${idProduct}/titulos/desembolsos/${outgoId}/clientes/naoelegiveis?${params}`);

export const listEligiblesNFsFromClient = (idProduct, outgoId, clientId, params) =>
    axios.get(
        `/v2/${idProduct}/titulos/desembolsos/${outgoId}/clientes/${clientId}/notas/elegiveis?${params}`
    );

export const listIneligiblesNFsFromClient = (idProduct, outgoId, clientId, params) =>
    axios.get(
        `/v2/${idProduct}/titulos/desembolsos/${outgoId}/clientes/${clientId}/notas/naoelegiveis?${params}`
    );

export const downloadEligiblesClientNFsFromOutgo = (idProduct, outgoId, clientId) =>
    axios.get(
        `/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/clientes/${clientId}/notas/elegiveis`,
        {
            responseType: 'blob',
        }
    );

export const downloadIneligiblesClientNFsFromOutgo = (idProduct, outgoId, clientId) =>
    axios.get(
        `/v2/${idProduct}/titulos/desembolsos/${outgoId}/download/clientes/${clientId}/notas/elegiveis`,
        {
            responseType: 'blob',
        }
    );

export const deleteClientsFromOutgo = (idProduct, outgoId, clientIds) =>
    axios.delete(`/v2/${idProduct}/titulos/desembolsos/remover/clientes/${outgoId}`, {
        data: { idsClientes: clientIds },
    });

export const deleteNFsFromOutgo = (idProduct, outgoId, nfIds) =>
    axios.delete(`/v2/${idProduct}/titulos/desembolsos/remover/notas/${outgoId}`, {
        data: { idsNotas: nfIds },
    });
