import axios from '../../axios.js';

const getOriginacoes = (id_produto, tipo, filters) =>
    axios.get(`/v2/${id_produto}/originacao/clientes/${tipo}?${filters}`);
const getNotas = (id_produto, id_cliente, tipo, filters) =>
    axios.get(`/v2/${id_produto}/originacao/cliente/${id_cliente}/notas/${tipo}?${filters}`);
const listStates = () => axios.get(`/v2/enums/estados`);
const users = id => axios.get(`/v2/cadastro/tipo/${id}`);
const getStatusLimit = id_produto => axios.get(`/v2/${id_produto}/limite/status`);
const listSegments = (id) => axios.get(`/v2/cadastro/produtos/${id}/segmentos`);
const getSimulacao = (id, filters) => axios.get(`/v2/${id}/originacao/simulacao?${filters}`);
const getAvailability = (id, filters) => axios.get(`/v2/${id}/originacao/pl/disponivel?data=${filters}`);
const selecaoManual = (id, payload) => axios.post(`/v2/${id}/originacao/selecaomanual`, payload);
const getOriginacao = (id, id_produto) => axios.get(`/v2/${id_produto}/originacao/${id}`);
const confirmOriginacao = (id, id_produto, payload) => axios.patch(`/v2/${id_produto}/originacao/confirmacao/${id}`, payload);
const exportOriginacao = (id, id_produto) => axios.get(`/v2/${id_produto}/originacao/download/${id}`, {
    responseType: 'blob',
});
const selecaoSmart = (id_produto, payload) => axios.post(`/v2/${id_produto}/originacao/smart`, payload);

export default {
    getOriginacoes,
    getNotas,
    listStates,
    users,
    getStatusLimit,
    listSegments,
    getSimulacao,
    getAvailability,
    selecaoManual,
    getOriginacao,
    confirmOriginacao,
    exportOriginacao,
    selecaoSmart
};
