import axios from '@/axios';
import config from '@/helpers/config';

export const getUFLimiteUsado = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/uf-limite-usado?${filters}`);

export const getAverageClientExposure = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/exposicao-media-cliente?${filters}`);

export const getBalancesPerUF = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/uf-saldo-avencer?${filters}`);

export const getClientsCount = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/clientes?${filters}`);

export const getDelayGrowLastDays = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/indicadores-atraso?${filters}`);

export const getBalanceDue = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/saldo-a-vencer?${filters}`);

export const getOverdueBalance = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/em-atraso?${filters}`);

export const getTop10Exposure = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/exposicao-top-dez?${filters}`);

export const getAvailableCash = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/caixa-disponivel?${filters}`);

export const getTop40ClientsExposure = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/clientes-quarenta?${filters}`);

export const getOverdueClients = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/clientes-atraso?${filters}`);

export const getWallet = async (idProduct, filters) =>
    axios.get(`${config.apiDashboardUrl}/${idProduct}/carteira?${filters}`);
