export default [
    'findByDocumentStatus',
    'limitRequestStatus',
    'limitsRequestStatus',
    'limitsPerRootStatus',
    'uploadExcelRequestStatus',
    'listClientsByGroupStatus',
    'requestRequestStatus',
    'limitsPerBatchRequestStatus',
    'createRegisterStatus',
    'downloadLimitsRequestStatus',
    'limitsFromClientListRequestStatus'
];
