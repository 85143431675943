export default {
    get apiUrl() {
        return `${process.env.VUE_APP_BACK}`;
    },
    get baseUrl() {
        return process.env.VUE_APP_BACK;
    },
    get apiDashboardUrl() {
        return `${process.env.VUE_APP_DASHBOARD}`;
    },
};
