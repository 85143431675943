import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
});

const options = {
    theme: {
        themes: {
            light: {
                primary: '#8687a7',
                secondary: '#00B494',
                background: '#fff',
                dark: '#495057',
                danger: '#F44336',
                warning: '#FF9800',
                altsecondary: '#e5f7f4',
                pending: '#feeceb',
                altPrimary: '#707d8a',
                altBlue: '#003366',
            },
        },
        options: {
            customProperties: true,
        },
    },
};

export default new Vuetify(options);
