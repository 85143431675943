import originacaoService from '../service';
import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import { _fetchDefaultParser } from '@/helpers/store';
import managerFromAPI from '@/helpers/dtos/managerFromAPI';
import queryString from '@/helpers/dtos/queryString';
import originationFromAPI from '@/helpers/dtos/originationFromAPI';

export default {
    async fetchOriginacoes({ commit }, { id_produto, tipo, filters }) {
        commit('setOriginacoesListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await originacaoService.getOriginacoes(id_produto, tipo, params);
            _fetchDefaultParser(commit, data, originationFromAPI, 'OriginacoesList');
        } catch (error) {
            commit('setOriginacoesListRequestStatus', RequestStatusEnum.ERROR);
        }
    },

    async getStates({ commit }) {
        const { data } = await originacaoService.listStates();
        commit('setStatesList', data.data);
    },

    async fetchSimulacao({ commit }, { id, ...filters }) {
        try {
            const params = queryString(filters, {});

            const { data } = await originacaoService.getSimulacao(id, params);
            commit('setSimulacaoList', Object.values(data.data));
        } catch (error) {
            commit('setSimulacaoList', []);
        }
    },

    async getAvailability({ commit }, { id, type, date }) {
        try {
            const { data } = await originacaoService.getAvailability(id, date);
            if (type === 'TODAY') {
                commit('setAvailabilityToday', data.data);
            } else {
                commit('setAvailabilityDate', data.data);
            }
        } catch (error) {
            commit('setSimulacaoList', []);
        }
    },

    async listUsers({ commit }, { id }) {
        try {
            const { data } = await originacaoService.users(id);
            const arr = data.data.map(item => managerFromAPI(item));
            commit('setUsersList', arr);
        } catch (error) {
            commit('setUsersList', []);
        }
    },

    async getSegments({ commit }, { id_produto }) {
        try {
            const { data } = await originacaoService.listSegments(id_produto);
            commit('setSegmentsList', data.data);
        } catch (error) {
            commit('setSegmentsList', []);
        }
    },

    async selecaoManual({ commit }, { id_produto, ...payload }) {
        try {
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.START);

            const { data } = await originacaoService.selecaoManual(id_produto, payload);
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setOriginationId', data.meta.id_originacao);
        } catch (error) {
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.ERROR);
        }
    },

    async selecaoSmart({ commit }, { id_produto, ...payload }) {
        try {
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.START);

            const { data } = await originacaoService.selecaoSmart(id_produto, payload);
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setOriginationId', data.meta.id_originacao);
        } catch (error) {
            commit('setSelecaoManualRequestStatus', RequestStatusEnum.ERROR);
        }
    },
    async getOriginacao({ commit }, { id_produto, id }) {
        try {
            commit('setOriginacaoRequestStatus', RequestStatusEnum.START);
            const { data } = await originacaoService.getOriginacao(id, id_produto);
            commit('setOriginacaoRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setOriginacao', { ...data.data, ...data.meta });
        } catch (error) {
            commit('setOriginacaoRequestStatus', RequestStatusEnum.ERROR);
        }
    },
    async confirmOriginacao({ commit }, { id_produto, id }) {
        try {
            const payload = {
                aceite: 1,
            };
            commit('setConfirmOriginacaoRequestStatus', RequestStatusEnum.START);

            await originacaoService.confirmOriginacao(id, id_produto, payload);
            commit('setConfirmOriginacaoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setConfirmOriginacaoRequestStatus', RequestStatusEnum.ERROR);
        }
    },

    async exportOriginacao({ commit }, { id_produto, id }) {
        commit('setExportOriginacaoRequestStatus', RequestStatusEnum.START);
        try {
            const data = await originacaoService.exportOriginacao(id, id_produto);

            commit('setExportOriginacao', { file: data.data });
            commit('setExportOriginacaoRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setExportOriginacaoRequestStatus', newErrorBuilder(error));
        }
    },

    resetState({ commit }) {
        commit('resetState');
    },

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },
};
