export default _storage => {
    return {
        checkAuth: (to, from, next) => {
            const loggedIn = _storage.getItem('Token');
            if (!loggedIn && to.path.indexOf('/admin/') >= 0) {
                return next('/login');
            }
            if (loggedIn && to.path.indexOf('/admin/') < 0 && to.path.indexOf('/produto') < 0) {
                return next('/admin/dashboard');
            }

            next();
        },
    };
};
