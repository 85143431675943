import { limitsMappingKeys } from './mappings';

export default rawData => {
    let obj = {};
    
    Object.keys(limitsMappingKeys).forEach(key => {
        obj[limitsMappingKeys[key]] = rawData[key];
    });

    obj.approved =  { value: rawData["valor"], label: "Aprovado", approvalDate: rawData["dataAprovacao"] };

    obj.available = { value: rawData["disponivel"], label: "Disponível" };
    obj.movement = { value: rawData["movimentoElegibilidade1"], label: "Movimento" };
    obj.used = { value: rawData["utilizado"], label: "Utilizado" };

    return obj;
};