import { gettersBuilder } from '@/helpers/store';
import stateBasicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';

export default {
    listClientsByGroupResult: state => ({
        items: state.listClientsByGroup,
        totalPages: state.listClientsByGroupTotalPages,
    }),
    listLimitsPerBatchResult: state => ({
        items: state.limitsPerBatch,
        totalPages: state.limitsPerBatchPages,
    }),
    listLimitsResult: state => ({
        items: state.limits,
        totalPages: state.limitsPages,
    }),
    limitsFromClientListResult: state => ({
        items: state.limitsFromClientList,
        totalPages: state.limitsFromClientListTotalPages,
    }),

    statesEnumAsArray: state => {
        if (!state.states) {
            return [];
        }
        return Object.values({ ...state.states });
    },
    ...gettersBuilder(stateBasicKeys),
    selectedProduct: state => state.selectedProduct,
    originators: state => state.originators,
    ...gettersBuilder(requestStatusKeys),
};
