import defaultState from './state';
export default {
    resetState(state) {
        Object.assign(state, defaultState);
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    setProfileRequestStatus(state, status) {
        state.profileRequestStatus = status;
    },
};
