import { signIn, profile, askToken, passwordRecovery } from '../service';
import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import profileFromAPI from '@/helpers/dtos/profileFromAPI';

export default {
    resetState({ commit }) {
        commit('resetState');
    },

    keepConnected({ commit }) {
        commit('setPermanecerConectado', true);
    },

    async login({ commit }, { email, senha }) {
        commit('setSignInRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await signIn({ email, senha });
            commit('setToken', data.data.token);
            commit('setSignInRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit("setSignInRequestStatus", newErrorBuilder(error));
        }
    },
    logout({ commit }) {
        commit('resetToken');
        commit('resetState');
    },
    async fetchProfile({ commit }) {
        commit('setProfileRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await profile();
            commit('setProfileRequestStatus', RequestStatusEnum.SUCCESS);
            const newProfileData = profileFromAPI(data.data);
            commit('setProfile', newProfileData);
        } catch (error) {
            commit('setProfileRequestStatus', newErrorBuilder(error));
        }
    },
    async askToken({ commit }, email) {
        commit('setAskPasswordResetRequestStatus', RequestStatusEnum.START);
        try {
            await askToken(email);
            commit('setAskPasswordResetRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setAskPasswordResetRequestStatus', newErrorBuilder(error));
        }
    },
    async passwordRecovery({ commit }, params) {
        commit('setPasswordRecoveryRequestStatus', RequestStatusEnum.START);
        try {
            await passwordRecovery(params);
            commit('setPasswordRecoveryRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setPasswordRecoveryRequestStatus', newErrorBuilder(error));
        }
    },
};
