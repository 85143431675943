import { profile } from '../../../services/admin';
import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import profileFromAPI from '@/helpers/dtos/profileFromAPI';

export default {
    resetState({ commit }) {
        commit('resetState');
    },

    async fetchProfile({ commit }) {
        commit('setProfileRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await profile();

            const newProfileData = profileFromAPI(data.data);
            commit('setProfile', newProfileData);
            commit('setProfileRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setProfileRequestStatus', newErrorBuilder(error));
        }
    },
};
