export const storage = (_storage) => {

    function getItem(key, defaultValue) {

        const item = _storage.getItem(key) || defaultValue;

        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }

    }

    function setItem(key, value) {
        try {
            if (typeof value === 'string') {
                _storage.setItem(key, value);
            } else {
                _storage.setItem(key, JSON.stringify(value));
            }
            return true;
        } catch (e) {
            return false;
        }

    }

    function  clearKey(key) {
        return _storage.removeItem(key);
    }

    function clear() {
        return _storage.clear();
    }

    return {
        getItem,
        setItem,
        clear,
        clearKey
    };

};

export default storage(localStorage);