
export const newOriginatorFromAPI = rawOriginator => {
    return {
        id: rawOriginator.id,
        name: rawOriginator.nome,
        apiPhotoUrl: rawOriginator.foto,
        code: rawOriginator.codigo,
        products: rawOriginator.produtos.map(product => {
            return {
                idMaster: product.idCadastroMaster,
                id: product.idCadastro,
                register: {
                    id: product.cadastro.id,
                    name: product.cadastro.nome
                }
            };
        })
    };
};