export default [
    'upcomingDuesRequestStatus',
    'limitsUsagePerUFRequestStatus',
    'balancesPerUFRequestStatus',
    'fundWalletRequestStatus',
    'averageClientExposureRequestStatus',
    'clientsCountRequestStatus',
    'delayGrowLastDaysRequestStatus',
    'balanceDueRequestStatus',
    'top10ExposureRequestStatus',
    'availableCashRequestStatus',
    'overdueBalanceRequestStatus',
    'top40ClientsExposureListRequestStatus',
    'overdueClientsListRequestStatus',
];
