const Login = () => import('@/mfes/auth/views//Login');
const Portal = () => import('@/mfes/auth/views/LoginPortal');
const RecuperarSenha = () => import('@/mfes/auth/views/RecuperarSenha');

export default [
    {
        path: 'login',
        name: 'login',
        component: Login,
    },
    {
        path: 'produto',
        name: 'portal',
        component: Portal,
    },
    {
        path: 'recupecao-senha',
        name: 'recuperarSenha',
        component: RecuperarSenha,
    },
];
