import { mutationsBuilder } from '@/helpers/store';
import basicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';
import defaultState from './state';

export default {
    ...mutationsBuilder(basicKeys),
    ...mutationsBuilder(requestStatusKeys),
    resetState(state) {
        const selectedProduct = { ...state.selectedProduct };
        Object.assign(state, defaultState);
        state.selectedProduct = selectedProduct;
    },
    setBaseData(state, { selectedProduct }) {
        state.selectedProduct = selectedProduct;
    },
};
