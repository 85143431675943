const Produtores = () => import('@/mfes/cadastros/views/Produtores');
const EditarProdutor = () => import('@/mfes/cadastros/views/produtores/Detalhes/EditarProdutor');

export default [
    {
        path: 'produtores/editar/:id',
        name: 'Editar Produtor',
        component: EditarProdutor,
        meta: { title: 'Editar Produtor' },
    },
    {
        path: 'produtores/:step',
        name: 'Produtores',
        component: Produtores,
        meta: { title: 'Produtores' },
    },
    {
        path: 'produtores',
        redirect: 'produtores/consultar',
    },
];
