import { buildStateRequestStatus } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';

export default {
    limits: [],
    statusList: [],
    limitsPages: null,
    limit: null,
    listClientsByGroup: null,
    listClientsByGroupTotalPages: null,
    limitsPerBatch: [],
    limitsPerBatchPages: null,
    limitsPerRootResult: null,
    managersList: null,
    segments: [],
    findByDocumentResult: null,
    downloadLimitsResult: null,
    ...buildStateRequestStatus(requestStatusKeys),
    states: null,
    selectedProduct: null,
    originators: null,
    limitsFromClientList: [],
    limitsFromClientListTotalPages: null,
};
