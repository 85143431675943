import { tituloMappingKeys, noteMappingKeys } from './mappings';

export default rawData => {
    const obj = Object.fromEntries(
        Object.keys(tituloMappingKeys).map(key => [[tituloMappingKeys[key]], rawData[key]])
    );

    obj.notes = rawData.notas.map(item => {
        let note = {};
        Object.keys(noteMappingKeys).forEach(key => {
            note[noteMappingKeys[key]] = item[key];
        });
        return note;
    });

    return obj;
};
