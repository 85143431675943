import { init  } from '@/store/localStorage/localStorage';
import { RequestStatusEnum } from '@/helpers/consts';

export default {
  token: init,
  permanecerConectado: false,
  profile: null,
  askPasswordResetRequestStatus: RequestStatusEnum.IDLE,
  passwordRecoveryRequestStatus: RequestStatusEnum.IDLE,
  signInRequestStatus: RequestStatusEnum.IDLE,
  profileRequestStatus: RequestStatusEnum.IDLE
};