import { mutationsBuilder } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';

export default {

    setBaseData(state, { selectedProduct }) {
        state.selectedProduct = selectedProduct;
    },
    ...mutationsBuilder(requestStatusKeys),
};
