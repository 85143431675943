import { register as mappingKeys, registerConfig as registerConfigMappingKeys } from './mappings';

export default rawData => {
    let obj = {};
    Object.keys(mappingKeys).forEach(key => {
        obj[mappingKeys[key]] = rawData[key];
    });

    if (rawData.config) {
        obj.config = buildConfig(rawData.config);
    }

    if (rawData.configuracoes && rawData.configuracoes.length > 0) {
        obj.configuracoes = rawData.configuracoes.map( c => buildConfig(c));
    }

    return obj;
};

const buildConfig = _rawData => {
    let newObj = {};

    Object.keys(registerConfigMappingKeys).forEach(key => {
        newObj[registerConfigMappingKeys[key]] = _rawData[key];
    });
    
    return newObj;
};