import { gettersBuilder } from '@/helpers/store';
import stateBasicKeys from './_basicKeys';
import requestStatusKeys from './_requestStatusKeys';
export default {
    titulosListResult: state => ({
        items: state.titulosList,
        totalPages: state.titulosListTotalPages,
    }),
    followUpListResult: state => ({
        items: state.followUpList,
        totalPages: state.followUpListTotalPages,
    }),
    outgoesListResult: state => ({
        items: state.outgoesList,
        totalPages: state.outgoesListTotalPages,
    }),
    titulosModalListResult: state => ({
        items: state.titulosModalList,
        totalPages: state.titulosModalListTotalPages,
    }),
    deliveriesListResult: state => ({
        items: state.deliveriesList,
        totalPages: state.deliveriesListTotalPages,
    }),
    clientsModalListResult: state => ({
        items: state.clientsModalList,
        totalPages: state.clientsModalListTotalPages,
    }),
    NFsModalListResult: state => ({
        items: state.NFsModalList,
        totalPages: state.NFsModalListTotalPages,
    }),
    ...gettersBuilder(stateBasicKeys),
    ...gettersBuilder(requestStatusKeys),
    selectedProduct: state => state.selectedProduct,
};
